export const getAllEvents = () =>
  fetch('/.netlify/functions/api/events/fetch', {
  method: 'GET',
  headers: {
      'Content-Type': 'application/json',
  }
}).then(res => res.json())
  .then(res => res.data)
  .catch((err) => err);

export const getEvent = ({ id }) =>
  fetch(`/.netlify/functions/api/events/${id}`, {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
    }
  }).then(res => res.json())
    .then(res => res.data)
    .catch((err) => err);

export const addEvent = ({ event }) =>
  fetch(`/.netlify/functions/api/events/create`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(event)
  }).then(res => res.json())
    .then(res => res.data)
    .catch((err) => err);

export const updateEvent = ({ eventId, values }) =>
  fetch(`/.netlify/functions/api/events/update`, {
    method: 'PATCH',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({eventId, values})
  }).then(res => res.json())
    .then(res => res.data)
    .catch((err) => err);

export const deleteEvent = ({ id }) =>
  fetch(`/.netlify/functions/api/events/delete/${id}`, {
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json',
    },
  }).then(res => res.json())
    .then(res => res.data)
    .catch((err) => err);

export const getEventImages = ({ collection, doc, subCollection }) =>
  fetch(`/.netlify/functions/api/events/images`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({ collection, doc, subCollection })
  }).then(res => res.json())
    .then(res => res.data)
    .catch((err) => err);

export const deleteImageFromStorage = ({ filename }) =>
  fetch(`/.netlify/functions/api/events/delete/image`, {
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({ filename })
  }).then(res => res.json())
    .then(res => res.data)
    .catch((err) => err);

export const addEventImage = ({ event, image }) =>
  fetch(`/.netlify/functions/api/events/add/image`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({ event, image })
  }).then(res => res.json())
    .then(res => res.data)
    .catch((err) => err);
