export default {
  CHANGE_EVENT_VIEW: "CHANGE_EVENT_VIEW",
  GET_EVENT: "GET_EVENT",
  GET_EVENT_SUCCESS: "GET_EVENT_SUCCESS",
  GET_EVENT_FAILURE: "GET_EVENT_FAILURE",
  GET_ALL_EVENTS: "GET_ALL_EVENTS",
  GET_ALL_EVENTS_SUCCESS: "GET_ALL_EVENTS_SUCCESS",
  GET_ALL_EVENTS_FAILURE: "GET_ALL_EVENTS_FAILURE",
  ADD_EVENT: "ADD_EVENT",
  ADD_EVENT_SUCCESS: "ADD_EVENT_SUCCESS",
  ADD_EVENT_FAILURE: "ADD_EVENT_FAILURE",
  UPDATE_EVENT: "UPDATE_EVENT",
  UPDATE_EVENT_SUCCESS: "UPDATE_EVENT_SUCCESS",
  UPDATE_EVENT_FAILURE: "UPDATE_EVENT_FAILURE",
  SHOW_SELECTED_EVENT: "SHOW_SELECTED_EVENT",
  DELETE_EVENT: "DELETE_EVENT",
  DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
  DELETE_EVENT_FAILURE: "DELETE_EVENT_FAILURE",
  GET_EVENT_IMAGES: "GET_EVENT_IMAGES",
  GET_EVENT_IMAGES_SUCCESS: "GET_EVENT_IMAGES_SUCCESS",
  DELETE_IMAGE: "DELETE_IMAGE",
  DELETE_IMAGE_SUCCESS: "DELETE_IMAGE_SUCCESS",
  DELETE_IMAGE_FAILURE: "DELETE_IMAGE_FAILURE",
  EVENTS: "events",
  ADD_EVENT_IMAGE: "ADD_EVENT_IMAGE",
  ADD_EVENT_IMAGE_SUCCESS: "ADD_EVENT_IMAGE_SUCCESS",
  ADD_EVENT_IMAGE_FAILURE: "ADD_EVENT_IMAGE_FAILURE"
};
