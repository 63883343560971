import React, { useState } from "react";
import "./header.css";
import logo from "../../assets/img/sl.png";
import { Link } from "react-router-dom";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import Login from "../main/Login/login-component";
import { connect } from "react-redux";
import LoggedIn from "../main/Login/loggedIn-component";
import { FaWindowClose } from "react-icons/fa";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDrawer: false,
    };
  }

  componentDidMount = () => {
    //initial auth status
  };

  scrollTop = () => {
    window.scrollTo(0, 0);
  };

  handleToggle = () => {
    this.setState({ showDrawer: !this.state.showDrawer });
  };

  handleClose = () => {
    this.setState({ showDrawer: false });
  };

  render() {
    return (
      <>
        <Navbar
          ref={this.navRef}
          collapseOnSelect
          expand="lg"
          className="header-navbar"
        >
          <Navbar.Brand>
            <Link className="header-logo" onClick={this.scrollTop} to="/">
              <img src={logo} alt="" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="drawer-navbar" onClick={this.handleToggle} className="d-lg-none" />
          <Navbar.Collapse id="drawer-navbar" className="d-none d-lg-flex">
            <Nav className="ms-auto me-5 pe-5">
              <Link className="header-nav-link" onClick={this.scrollTop} to="/">
                Home
              </Link>
              <Link className="header-nav-link" onClick={(e) => { e.preventDefault(); window.location.replace('/#about'); }}>
                About Us
              </Link>
              <Link className="header-nav-link" onClick={(e) => { e.preventDefault(); window.location.replace('/#galleries'); }}>
                Gallery
              </Link>
              <Link className="header-nav-link" onClick={(e) => { e.preventDefault(); window.location.replace('/#testimonies'); }}>
                Testimonies
              </Link>
              <Link className="header-nav-link" onClick={(e) => { e.preventDefault(); window.location.replace('/#voice-objectives'); }}>
                Objectives
              </Link>
              <Link className="header-nav-link" onClick={this.scrollTop} to="/events">
                Events
              </Link>
              <Link className="header-nav-link" onClick={this.scrollTop} to="/blogs">
                Blogs
              </Link>
              <Dropdown className="d-inline mx-2 header-nav-link">
                <Dropdown.Toggle as="a" id="dropdown-autoclose-true">
                  Resources
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as="div">
                    <Link
                      className="dropdown-link"
                      onClick={this.scrollTop}
                      to="/under-construction"
                    >
                      DYS Course
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="div">
                    <Link
                      className="dropdown-link"
                      onClick={this.scrollTop}
                      to="/sq-form"
                    >
                      SQ form
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="div">
                    <Link
                      className="dropdown-link"
                      onClick={this.scrollTop}
                      to="/donations"
                    >
                      Donate
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item as="div">
                    <Link
                      className="dropdown-link"
                      onClick={this.scrollTop}
                      to="/qna-forum"
                    >
                      Q&amp;A Forum
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {
                this.props.auth.isLoggedIn && !this.props.auth.isAnonymous ?
                  <LoggedIn />
                  :
                  <Login />
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        {this.state.showDrawer && ( // Menu Drawer for mobile view
          <div className="drawer">
            <div className="drawer-header">
              <h5>Menu</h5>
              <button className="drawer-close" onClick={this.handleClose}><FaWindowClose /></button>
            </div>
            <div className="drawer-body">
              <Nav className="flex-column text-center">
                <Link className="header-nav-link" onClick={() => { this.scrollTop(); this.handleClose(); }} to="/">
                  Home
                </Link>
                <Link className="header-nav-link" onClick={(e) => { e.preventDefault(); window.location.replace('/#about'); this.handleClose(); }}>
                  About Us
                </Link>
                <Link className="header-nav-link" onClick={(e) => { e.preventDefault(); window.location.replace('/#galleries'); this.handleClose(); }}>
                  Gallery
                </Link>
                <Link className="header-nav-link" onClick={(e) => { e.preventDefault(); window.location.replace('/#testimonies'); this.handleClose(); }}>
                  Testimonies
                </Link>
                <Link className="header-nav-link" onClick={(e) => { e.preventDefault(); window.location.replace('/#voice-objectives'); this.handleClose(); }}>
                  Objectives
                </Link>
                <Link className="header-nav-link" onClick={() => { this.scrollTop(); this.handleClose(); }} to="/events">
                  Events
                </Link>
                <Link className="header-nav-link" onClick={() => { this.scrollTop(); this.handleClose(); }} to="/blogs">
                  Blogs
                </Link>
                <Dropdown className="d-inline mx-2 header-nav-link">
                  <Dropdown.Toggle as="a" id="dropdown-autoclose-true">
                    Resources
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as="div">
                      <Link
                        className="dropdown-link"
                        onClick={() => { this.scrollTop(); this.handleClose(); }}
                        to="/under-construction"
                      >
                        DYS Course
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item as="div">
                      <Link
                        className="dropdown-link"
                        onClick={() => { this.scrollTop(); this.handleClose(); }}
                        to="/sq-form"
                      >
                        SQ form
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item as="div">
                      <Link
                        className="dropdown-link"
                        onClick={() => { this.scrollTop(); this.handleClose(); }}
                        to="/donations"
                      >
                        Donate
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item as="div">
                      <Link
                        className="dropdown-link"
                        onClick={() => { this.scrollTop(); this.handleClose(); }}
                        to="/qna-forum"
                      >
                        Q&amp;A Forum
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {
                  this.props.auth.isLoggedIn && !this.props.auth.isAnonymous ?
                    <LoggedIn />
                    :
                    <Login />
                }
              </Nav>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(Header);